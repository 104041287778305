.contact-info {

  @include mobile {
    padding-left: 0!important;
  }

  p {
    font-size: 13px;
    line-height: 1.7;
  }

  ul {
    li {
      &.tel {
        order: 7;
        margin-top: 10px;
      }

      &.email {
        margin: 45px 0 18px;
      }

      a {
        opacity: 1;
        color: $bluemain;

        &.tel,
        &.icon-email {
          font-size: 1rem;
          padding-left: 40px;
          position: relative;
        }

        &::before {
          content: '';
          width: 24px;
          height: 24px;
          display: block;
          position: absolute;
          left: 0;
        }

        &.icon-email:before {
          background: url(images/email-icon-blue.svg) center no-repeat;
        }

        &.tel:before {
          background: url(images/phone-icon-blue.svg) center no-repeat;
        }
      }
    }
  }

  @include desktop {
    padding-right: 7.5rem;
  }

}

.contact-form {
  @include mobile {
    padding-right: 0!important;
  }
  
  margin-bottom: 4rem;

  label {
    display: inline-block;
    margin-bottom: .625rem;
    font-size: .75rem;
    position: relative;
    color: $black;                            

    &.required {
      &::after {
        display: block;
        content: '*';
        font-size: .75rem;
        color: red;
        position: absolute;
        top: -.1rem;
        right: -.6rem;
      }
    }

  }

  input:not([type="submit"]) {
    margin-bottom: 1.5rem;
    display: block;
    width: 100%;
    height: 2.75rem;
    background-color: #fafafa;
    border: 1px solid #e0e0e0;
    border-radius: 3px;
    padding: 14px;
    color: $black;
    position: relative;
    transition: all .25s ease;

    &:valid {
      background-image: url(images/checkmark-green.svg);
      background-position: 99% center;
      background-repeat: no-repeat;
    }

    &.error {
      outline: 1px solid $redred;
    }

  }

  .grow-wrap {
    /* easy way to plop the elements on top of each other and have them both sized based on the tallest one's height */
    display: grid;
  }
  .grow-wrap::after {
    /* Note the weird space! Needed to preventy jumpy behavior */
    content: attr(data-replicated-value) " ";

    /* This is how textarea text behaves */
    white-space: pre-wrap;

    /* Hidden from view, clicks, and screen readers */
    visibility: hidden;
  }
  .grow-wrap > textarea {
    /* You could leave this, but after a user resizes, then it ruins the auto sizing */
    resize: none;
    word-wrap: break-word;

    /* Firefox shows scrollbar on growth, you can hide like this. */
    overflow: hidden;
  }
  .grow-wrap > textarea,
  .grow-wrap::after {
    /* Identical styling required!! */
    @include word-wrap;
    max-width: 100%;
    width: 100%;
    min-height: 9.125rem;
    padding: 12px 15px 10px;
    font-size: 1rem;
    line-height: 1.75rem;        

    /* Place on top of each other */
    grid-area: 1 / 1 / 2 / 2;
  }

  .error-message {
    font-size: .625rem;
    color: $redred;
    margin: -15px 0 10px;
  }

  textarea + .error-message {
    margin-top: 15px;
  }

  .success-message {
    opacity: 0;
    height: 0;
    transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);

    &.success {
      opacity: 1;
      height: auto;
    }
  }

}