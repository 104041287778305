.hero {
  margin-bottom: 1.0625rem;

  @include desktop {
    background: url(images/home-hero.jpg) top right no-repeat;
    background-size: cover;
    height: 110vh;    
    margin-bottom: 0;
  }

  @include widescreen {
    background-position: 0 -100px;
    margin-bottom: 0;
  }  
}

.hero-content {
  top: 18%;
  width: 90%;
  left: 50%;
  transform: translateX(-50%);

  @include desktop {
    top: 30%;
    left: 10%;
    transform: translateY(-30%);
    width: 50%;
    max-width: 706px;
  }


  h2 {
    margin-bottom: 1.5rem;
    transition: opacity ease-in-out 0.25s;
  }

  p {
    font-size: 1rem;
    margin-bottom: 2rem;

    @include tablet {
      font-size: 1.25rem;
    }
  }
}

.home-section {
  margin-bottom: 4rem;

  @include desktop {
    &.flex-col {
      flex-direction: row;
    }

    &.video {
      flex-direction: row-reverse;

      .btn {
        align-self: flex-start;
      }
    }
  }

  .text-block {
    margin-bottom: 2rem;

    h3 {
      margin-bottom: 1rem;

      @include desktop {
        margin-bottom: 2.5rem;
      }
    }

    p {
      font-size: 0.6875rem;
      line-height: 1.25rem;

      @include desktop {
        margin-bottom: 3rem;
      }
    }

    @include desktop {
      width: 35%;
      padding-left: 3.75rem;
      justify-content: center;

      &.about-process {
        width: 50%;
      }      

      &.left {
        padding-left: 0;
        padding-right: 3.75rem;
      }

      p {
        font-size: 0.875rem;
        line-height: 1.5rem;
      }

      .btn {
        display: inline-block !important;
      }
    }
  }

  @include desktop {
    .video-block {
      width: 65%;
    }
  }

  .thumbs-block {
    margin-bottom: 2.5rem;
  }

  .thumb {
    width: 47%;
    -webkit-box-shadow: 0px 0px 0.4375rem 0px rgba(40, 53, 147, 0.2);
    -moz-box-shadow: 0px 0px 0.4375rem 0px rgba(40, 53, 147, 0.2);
    box-shadow: 0px 0px 0.4375rem 0px rgba(40, 53, 147, 0.2);

    img {
      border-radius: 0.1875rem;
    }

    .thumb-caption {
      font-size: 0.5925rem;
      color: rgba(0, 0, 0, 0.87);
      line-height: 1.875rem;
      height: 1.875rem;
      padding-left: 1.5rem;
      justify-content: center;
      flex: 1;

      .caption {
        display: block;
        padding-left: 1.5rem;
        position: relative;

        @include desktop {
          padding-left: 2.5rem;
        }

        &:before {
          display: block;
          content: '';
          width: 1rem;
          height: 1rem;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translate3d(0, -50%, 0);
          background-image: url(images/icon-pencil.svg);
          background-position: center;
          background-repeat: no-repeat;
          background-size: 300%;

          @include desktop {
            width: 1.5625rem;
            height: 1.5625rem;
            background-size: auto;
          }
        }

        &.warehousing {
          &:before {
            background-image: url(images/icon-dolly.svg);
          }
        }
      }

      @include desktop {
        font-size: 1.5rem;
        line-height: auto;
        height: auto;
        padding: 1.5rem 0 1.5rem 1.5rem;
      }
    }
  }

  .cta-container {
    text-align: center;
  }
}

.industries-container {
  background: url(images//bg-cardboard-mobile.png) center no-repeat;
  background-size: cover;
  padding: 2.5rem 0;

  @include desktop {
    background: url(images//bg-cardboard-desktop.png) center no-repeat;
    background-size: cover;
  }

  .industries {
    flex-wrap: wrap;
    margin-top: 2.5rem;

    .industry {
      width: 50%;
      margin-bottom: 1.5rem;

      @include desktop {
        width: 25%;
        margin-bottom: 4rem;
      }

      .industry-icon {
        display: block;
        width: 5rem;
        height: 5.125rem;
        background-color: $white;
        background-image: url(images/icon-manufacture.svg);
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 50%;
        margin-bottom: 1.25rem;

        &.medical {
          background-image: url(images/icon-stethoscope.svg);
        }

        &.cosmetic {
          background-image: url(images/icon-skincare.svg);
        }

        &.pharma {
          background-image: url(images/icon-medicine.svg);
        }

        &.electronics {
          background-image: url(images/icon-robotic.svg);
        }

        &.retail {
          background-image: url(images/icon-package.svg);
        }

        &.military {
          background-image: url(images/icon-helmet.svg);
        }

        &.branding {
          background-image: url(images/icon-branding.svg);
        }
      }

      .industry-name {
        font-size: 0.8125rem;
        @include desktop {
          font-size: 1rem;
        }
      }
    }
  }
}

.solutions-header {
  margin: 64px auto 48px;
  width: 60%;

  @include mobile {
    margin-top: 60px;
  }

  h3 {
    margin-bottom: 42px;
  }

  p {
    font-size: .875rem;
    line-height: 1.5rem;
  }
}

.solutions-container {
  margin-top: 4rem;
  margin-bottom: 4rem;

  @include desktop {
    margin-top: 0;
    margin-bottom: 4rem;
    flex-direction: row;
  }

  .carousel-container {
    width: 100%;
    flex-wrap: nowrap;
    position: absolute;
    top: -9999em;
    left: -9999em;
    opacity: 0;
    transition: opacity 1s ease;
    margin-bottom: 2.5625rem;

    @include desktop {
      width: 64%;
      margin-bottom: 0;
    }

    &.active {
      position: relative;
      top: auto;
      left: auto;
      opacity: 1;
    }
  }

  .carousel-wrapper {
    width: 100%;
    max-width: 40.75rem;
    box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.2);
    border-radius: 5px;

    @include tablet {
      max-width: 100%;
    }

  }

  .slick-list {
    max-height: 510px;
  }

  .slick-slide {
    margin: 0;
    background-color: transparent;
    
    img {
      border-radius: 5px;
      width: 100%;
    }
  }

  .carousel-thumbs {
    width: 100%;

      .slick-dots {
        position: relative;
        bottom: auto;
        display: flex;
        list-style: none;
        width: 100%;
        max-width: 40.75rem;
        justify-content: space-between;
        
          @include tablet {
            max-width: 100%;
          }

        li {
          width: 6.375rem;
          height: 5rem;
          margin-bottom: 1.6875rem;
          opacity: .4;
          list-style: none;

            @include mobile {
              width: 20%;
              height: auto;
              margin-bottom: 0;
            }

          img {
            width: 100%;
          }

          &.slick-active {
            opacity: 1;
          }

          button {
            font-size: initial;
            line-height: initial;
            width: 100%;
            height: 100%;
            display: block;
            background-size: cover;

            &::before {
              opacity: 0;
            }
          }
        }
      }
    }
  }

  .carousel-thumb {
    margin-bottom: 1.6875rem;

    a {
      display: inline-block;
      &.active {
        outline: 2px solid $bluemain;
      }
    }
  }


.solutions-content {
  width: 100%;
  text-align: center;

    @include desktop {
      text-align: left;
      width: 36%;
    }

    @include mobile {
      margin-top: 10px!important;
    }


  h3 {
    margin-bottom: 2rem;
    text-align: left;

    @include desktop {
      margin-bottom: 2.5rem;
    }
  }

  li {
    margin-bottom: 1.5625rem;
    text-align: left;

    @include tablet {
      text-align: center;
    }

    @include mobile {
      text-align: center;
    }

    &:last-child {
      margin-bottom: 2.5rem;
    }

    h4 {
      font-weight: 400;
    }

    a {
      color: $grey400;

      &.active {
        color: $bluemain;
      }
    }

    p {
      position: absolute;
      left: -9999em;
      top: -9999em;
      font-size: 0.6875rem;
      line-height: 1.25rem;
      opacity: 0;
      transition: opacity 0.5s ease;

      &.active {
        position: relative;
        top: auto;
        left: auto;
        opacity: 1;
      }
    }
  }

  .btn {
    align-self: flex-start;

    @include tablet {
      align-self: center;
    }

    @include mobile {
      align-self: center;
    }

  }
}

.testimonials {
  margin-bottom: 40px;

  @include mobile {
    margin-bottom: 32px;
  }
}

.testimonial {
  width: 560px;
  height: auto;
  background: #fff;
  border: 1px solid #e0e0e0;
  box-shadow: 0 0 30px 0 rgba(40, 53, 147, 0.08);
  border-radius: 6px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
  line-height: 1.75;
  padding: 40px 40px 40px 96px;
    
  @include mobile {
    height: auto;
    padding-bottom: 20px;
  }

  p {
    position: relative;
    margin-bottom: 50px;

    &::before {
      content: '';
      display: block;
      position: absolute;
      z-index: 5;
      top: -15px;
      left: -72px;
      width: 48px;
      height: 40px;
      background: url(images/quotemark.svg) center no-repeat;
    }
  }

  .byline {
    font-size: 20px;
    position: relative;
    padding-top: 25px;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      border: 2px solid #e1f5fe;        
      width: 24px;
    }
    
    @include mobile {
      margin-bottom: 0;
    }
  }  

  @include mobile {
    width: 90%;
    height: auto;
    padding: 80px 24px 20px;

    p {
      &::before {
        top: -60px;
        left: 0;
      }
    }
  }
}

.slick-slide {
  margin: 0 25px;
}

.testimonials-control {
  margin-bottom: 120px;

  @include mobile {
    margin-bottom: 64px;
  }

  .arrow {
    width: 62px;
    height: 28px;
    background: url(images/testimonial-arrow.svg) 0 0 no-repeat;

    &.next {
      margin-left: 35px;
      transform: scaleX(-1)
    }

  }
}

.request-quote {
  .request-content {
    width: 90%;

    h3 {
      font-size: 1.375em;
      margin: 0 0 2.5rem; 
      line-height: 1.75;

      @include desktop {
        font-size: 2.375rem;
        margin-bottom: 2rem;
      }

    }

    p {
      font-size: 1.25em;
      margin: 0 0 3rem;
    }
  }

  &.alt {

    p {
      font-size: .8125rem;
      line-height: 1.7;
    }

    @include desktop {
      .request-content {
        width: 80%;

        h3 {
          font-size: 2.375rem;
          line-height: 1.3;
        }

        p {
          font-size: 1.25rem;
          line-height: 1.75;
        }

      }
    }

  }
}