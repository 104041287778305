$white: #fff;
$black: #000;
$bluemain: #283593;
$redred: #f44336;
$grey50: #fafafa;
$grey100: #f5f5f5;
$grey300: #e0e0e0;
$grey400: #bdbdbd;
$grey500: #9e9e9e;
$grey600: #757575;
$grey700: #616161;
$grey800: #424242;
$grey900: #212121;

.white {
  color: $white;
}

.black {
  color: $black;
}

.bluemain {
  color: $bluemain;
}

.redred {
  color: $redred;
}

.grey50 {
  color: $grey50;
}

.grey100 {
  color: $grey100;
}

.grey300 {
  color: $grey300;
}

.grey400 {
  color: $grey400;
}

.grey500 {
  color: $grey500;
}

.grey600 {
  color: $grey600;
}

.grey700 {
  color: $grey700;
}

.grey800 {
  color: $grey800;
}

.grey900 {
  color: $grey900;
}

//? override for project clarity

$color-primary: $bluemain;
$color-secondary: $black;
$color-tertiary: $grey600;

.inner-width {
  max-width: 90%;
}

// =============================================
// Layout - utility classes - AKD
// =============================================

.center-v {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.center-h {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.center-vh {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3D(-50%, -50%, 0);
}

.hide-text {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

.desktop-only {
  display: none !important;
  @media only screen and (min-width: 1024px) {
    display: block !important;
  }
}

.mobile-only {
  display: none !important;
  @media only screen and (max-width: 1023px) {
    display: block !important;
  }
}

//text alignment

.tac {
  text-align: center;
}

.tal {
  text-align: left;
}

.tar {
  text-align: right;
}

//widths and stuff

.w-100 {
  width: 100%;
}

.w-50 {
  width: 50%;
}

.w-33 {
  width: 33.333333333%;
}

.w-25 {
  width: 25%;
}

.maw-100 {
  max-width: 100%;
}

@include tablet {
  .w-t-100 {
    width: 100%;
  }

  .w-t-50 {
    width: 50%;
  }

  .w-t-25 {
    width: 25%;
  }
}

@include mobile {
  .w-m-100 {
    width: 100%;
  }

  .w-m-50 {
    width: 50%;
  }

  .w-m-25 {
    width: 25%;
  }
}

//display and positioning stuff

.d-block {
  display: block;
}

.d-i-block {
  display: inline-block;
}

.d-flex {
  display: flex;
}

.flexxy-wrap,
.d-flexxy-wrap {
  flex-wrap: wrap;
}

.flex-col {
  flex-direction: column;
}

.flex-centered {
  align-items: center;
  justify-content: center;
}

.jc-around {
  justify-content: space-around;
}

.jc-between {
  justify-content: space-between;
}

.jc-even {
  justify-content: space-evenly;
}

.jc-center {
  justify-content: center;
}

.ai-c {
  align-items: center;
}

.m-x-a {
  margin-left: auto;
  margin-right: auto;
}

.m-y-a {
  margin-top: auto;
  margin-bottom: auto;
}

@include mobile {

  .m-flex-row {
    flex-direction: row;
  }

  .m-flex-col {
    flex-direction: column;
  }
}

//positioning

.pos-r {
  position: relative;
}

.pos-a {
  position: absolute;
}

.pos-f {
  position: fixed;
}

//margin and padding: from https://gist.github.com/jacurtis/30da4bf9a6c9b9b5cc0aebac512ca7c9

$spaceamounts: (
  5,
  10,
  15,
  20,
  25,
  30,
  35,
  40,
  45,
  50,
  55,
  60,
  65,
  70,
  75,
  80,
  85,
  90,
  100,
  auto
); // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right); // Leave this variable alone

@each $space in $spaceamounts {
  @each $side in $sides {

    .m-#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px;
    }

    @include tablet-only {
      .md-m-#{str-slice($side, 0, 1)}-#{$space} {
        margin-#{$side}: #{$space}px !important;
      }    
    }

    @include mobile {
      .sm-m-#{str-slice($side, 0, 1)}-#{$space} {
        margin-#{$side}: #{$space}px !important;
      }    
    }    

    .m-a-#{$space} {
      margin: #{$space}px !important;
    }

    .m-x-#{$space} {
      margin-left: #{$space}px !important;
      margin-right: #{$space}px !important;
    }

    .m-y-#{$space} {
      margin-top: #{$space}px !important;
      margin-bottom: #{$space}px !important;
    }

    .p-#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }

    .p-a-#{$space} {
      margin: #{$space}px !important;
    }
  }
}

