.container {
  max-width: 1440px;
  padding-top: 0.72rem;

  @include desktop {
    padding-top: 0.933333rem;
  }
}

.full-bleed {
  width: 100vw;
  margin-left: 50%;
  transform: translateX(-50%);
}