  //! import google fonts
  // @import url('https://fonts.googleapis.com/css?family=Libre+Baskerville:400,400i,700');
// OR use @font-face like below

/* noto-sans-jp-regular - latin */
@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 400;
  src: local('Noto Sans Japanese Regular'), local('NotoSansJapanese-Regular'),
    url('/fonts/noto-sans-jp-v27-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/noto-sans-jp-v27-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/fonts/noto-sans-jp-v27-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/noto-sans-jp-v27-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('/fonts/noto-sans-jp-v27-latin-regular.svg#NotoSansJP') format('svg'); /* Legacy iOS */
}
/* noto-sans-jp-500 - latin */
@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 500;
  src: url('/fonts/noto-sans-jp-v27-latin-500.eot'); /* IE9 Compat Modes */
  src: local('Noto Sans Japanese Medium'), local('NotoSansJapanese-Medium'),
    url('/fonts/noto-sans-jp-v27-latin-500.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/noto-sans-jp-v27-latin-500.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/fonts/noto-sans-jp-v27-latin-500.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/noto-sans-jp-v27-latin-500.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('/fonts/noto-sans-jp-v27-latin-500.svg#NotoSansJP') format('svg'); /* Legacy iOS */
}
/* noto-sans-jp-700 - latin */
@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 700;
  src: url('/fonts/noto-sans-jp-v27-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Noto Sans Japanese Bold'), local('NotoSansJapanese-Bold'),
    url('/fonts/noto-sans-jp-v27-latin-700.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/noto-sans-jp-v27-latin-700.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/fonts/noto-sans-jp-v27-latin-700.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/noto-sans-jp-v27-latin-700.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('/fonts/noto-sans-jp-v27-latin-700.svg#NotoSansJP') format('svg'); /* Legacy iOS */
}
/* noto-sans-jp-900 - latin */
@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 900;
  src: url('/fonts/noto-sans-jp-v27-latin-900.eot'); /* IE9 Compat Modes */
  src: local('Noto Sans Japanese Black'), local('NotoSansJapanese-Black'),
    url('/fonts/noto-sans-jp-v27-latin-900.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/noto-sans-jp-v27-latin-900.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/fonts/noto-sans-jp-v27-latin-900.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/noto-sans-jp-v27-latin-900.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('/fonts/noto-sans-jp-v27-latin-900.svg#NotoSansJP') format('svg'); /* Legacy iOS */
}

//! set font variables
$font-heading: 'Noto Sans JP', Fallback, 'Arial', sans-serif;
$font-body: 'Noto Sans JP', Fallback, 'Arial', sans-serif;

body {
  font-family: $font-body;
  font-size: 1rem;
  color: $black;
}

h1 {
  font-family: $font-heading, serif;
  font-size: 1.7rem;
  font-weight: 700;
}

h2 {
  font-size: 1.625rem;
  font-weight: 700;

  @include tablet {
    font-size: 3rem;
  }
}

h3 {
  font-size: 1.375rem;

  @include tablet {
    font-size: 2.375rem;
  }
}

@mixin word-wrap() {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}