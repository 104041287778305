.services {
  padding-top: 6.875rem;

  @include desktop {
    padding-top: 11.5625rem;
  }
  
  h1 {
    font-size: 1.625rem;
    margin-bottom: 3rem;

    @include desktop {
      font-size: 3.4375rem;
      margin-bottom: 6.25rem;      
    }
  }

  .thumbs-block {
    margin: 0 .9375rem 2.5rem;

    @include desktop {
      max-width: 58.75rem;
      margin: 0 auto 6.25rem;
      justify-content: space-between;      
    }
  }

  .thumb-wrapper {
    margin-bottom: 2rem;

    @include desktop {
      width: 47%;
      max-width: 26.25rem;
    }
  }

  .thumb {
    width: 100%;
    -webkit-box-shadow: 0px 0px .4375rem 0px rgba(40, 53, 147, .2);
    -moz-box-shadow: 0px 0px  4375rem 0px rgba(40, 53, 147, .2);
    box-shadow: 0px 0px .4375rem 0px rgba(40, 53, 147, .2);
    margin-bottom: 2rem;

    img {
      border-radius: .1875rem;
    }

    .thumb-caption {
      font-size: 1.1875rem;
      color: rgba(0, 0, 0, .87);
      line-height: 3.75rem;
      height: 3.75rem;
      padding-left: 1.5rem;
      justify-content: center;
      flex: 1;

      @include desktop {
        height: 5rem;
        line-height: 5rem;                  
      }

      .caption {
        display: block;
        padding-left: 1.5rem;
        position: relative;

        @include desktop {
          padding-left: 2.5rem;
          font-size: 1.5rem;
          line-height: 1.5rem;
        }

        &:before {
          display: block;
          content: '';
          width: 1.125rem;
          height: 1.125rem;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translate3d(0, -50%, 0);
          background-image: url(images/icon-pencil.svg);
          background-position: center;
          background-repeat: no-repeat;
          background-size: 300%;

          @include desktop {
            width: 1.5625rem;
            height: 1.7625rem;
            background-size: auto;
          }
        }

        &.warehousing {
          &:before {
            background-image: url(images/icon-dolly.svg);
          }
        }
      }

      @include desktop {
        font-size: 1.5rem;
        line-height: auto;
        height: auto;
        padding: 1.5rem 0 1.5rem 1.5rem;
      }
    }
  }

  .service-description {
    font-size: .8125rem;
    line-height: 1.7;

    @include desktop {
      font-size: 1rem;
      line-height: 1.75;
    }

  }
}
