footer {
  padding: 1.5rem 0;

  @include desktop {

    &.flex-col {
      flex-direction: row-reverse;
      justify-content: space-between;
      width: 95%;
    }
  }

  .footer-contact {
    color: $bluemain;
    font-size: 0.6875rem;
    margin-bottom: 1.125rem;

    @include desktop {
      margin-bottom: 0;
    }

    a {
      color: $bluemain;
    }
  }

  .copyright {
    p {
      font-size: 0.75rem;
    }
  }
}
