// *::selection {
//   background: $pink;
// }

html,
body {
  height: 100%;
}

body {
  width: 100%;
  min-height: 100%;
  margin: 0;
}

.wrapper {
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: $bluemain;
}

img {
  display: block;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.float-in {
  transition: opacity 2s ease, margin-top 2s ease;  
}

.phantom {
  opacity: 0;
  margin-top: 100px;
}

.btn {
  background: $bluemain;
  border-radius: 1.375rem;
  color: #fff;
  display: inline-block;
  font-weight: 500;
  text-align: center;
  padding: 0.6875rem 3rem;

  &.inverse {
    background: transparent;
    border: 0.125rem solid $bluemain;
    color: $bluemain;
  }
}

//embed container for responsive videos

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;

  iframe,
  object,
  embed,
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
