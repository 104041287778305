.header {
  background-color: #fff;
  box-shadow: 0.0625rem 0.0625rem 0.25rem 0 rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100%;
  z-index: 3;

  ul {
    overflow: hidden;
    background-color: $bluemain;
    padding-top: 15vh;
  }

  li a {
    color: $white;
    display: block;
    padding: 0.625rem 1.25rem 0.625rem 3rem;
    text-decoration: none;
    font-size: 1.875rem;
    font-weight: 400;
    opacity: 0;
    transition: opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.5s;

    &.current {
      font-weight: 700;
    }

    &.tel {
      color: $bluemain;

      @include mobile {
        margin-top: 30px;
      }
    }

    &:hover {
      text-decoration: underline;
    }
  }

  .menu-btn:hover {
    background-color: #f4f4f4;
  }

  .logo {
    display: block;
    float: left;
    text-decoration: none;
    background: url(images/logo.svg) center no-repeat;
    background-size: 100%;
    width: 5.6875rem;
    height: 3.375rem;
    margin-left: 0.6875rem;
    transition: background 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.5s;
  }

  .menu {
    clear: both;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 6;
    transform: translateY(-100%);
    transition: transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.15s;
    will-change: transform;
    backface-visibility: hidden;
    display: flex;
    flex-direction: column;
  }

  .menu-icon {
    cursor: pointer;
    display: inline-block;
    position: fixed;
    top: 1.625rem;
    right: 1.25rem;
    user-select: none;
    z-index: 10;

    .navicon {
      background: #333;
      display: block;
      height: 0.125rem;
      position: relative;
      transition: background 1s ease-out;
      width: 1.125rem;

      &:before,
      &:after {
        background: #333;
        content: '';
        display: block;
        height: 100%;
        position: absolute;
        transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
        width: 100%;
      }

      &:before {
        top: 0.3125rem;
      }

      &:after {
        top: -0.3125rem;
      }
    }
  }

  .menu-btn {
    display: none;

    &:checked ~ {
      .logo {
        position: fixed;
        z-index: 10;
        background: url(images/logo-white.svg) center no-repeat;
      }

      .menu {
        position: fixed;
        top: 0;
        transform: translateY(0);
        transition: transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);

        li {
          &.tel {
            order: 7;
          }

          &.email {
            margin: 45px 0 18px;
          }

          a {
            opacity: 1;
            color: $white;

            &.tel,
            &.icon-email {
              color: $white;
              font-size: 1rem;
              padding-left: 88px;
              position: relative;
            }

            &.icon-email:before {
              content: '';
              width: 24px;
              height: 24px;
              display: block;
              background: url(images/email-icon-white.svg) center no-repeat;
              position: absolute;
              left: 40px;
            }

            &.tel:before {
              content: '';
              width: 24px;
              height: 24px;
              display: block;
              background: url(images/phone-icon-white.svg) center no-repeat;
              position: absolute;
              left: 40px;
            }
          }
        }
      }

      .menu-icon {
        .navicon {
          background: transparent;

          &:before {
            transform: rotate(-45deg);
            background: $white;
          }

          &:after {
            transform: rotate(45deg);
            background: $white;
          }
        }

        &:not(.steps) .navicon {
          &:before,
          &:after {
            top: 0;
          }
        }
      }
    }
  }
}

@include desktop {
  .header {
    .logo {
      height: 4.375rem;
      margin-left: 3.4375rem;
    }

    ul {
      padding: 0 3.4375rem 0;
      display: block;
      transform: none;
    }
    li {
      float: left;

      a {
        color: $black;
        padding: 1.25rem 1.5625rem;
        border-right: none;
        opacity: 1;

        &.icon-email {
          text-indent: -99999em;
          overflow: hidden;
          width: 1.5rem;
          height: 1.5rem;
          background: url(images/icon-email.svg) center no-repeat;
          padding: 60px 0 0;
        }
      }
    }

    .menu {
      display: block;
      clear: none;
      float: right;
      max-height: none;
      transform: none;
      transition: none;
      position: relative;
      top: auto;
      right: auto;
      bottom: auto;
      left: auto;
      z-index: auto;
      background: $white;

      li a {
        opacity: 1;
        color: $black;
        font-size: 1rem;
      }
    }

    .menu-icon {
      display: none;
    }
  }
}
