.solutions {
  padding-top: 6.875rem;

  @include desktop {
    padding-top: 11.5625rem;
  }
  
                       h1 {
    font-size: 1.625rem;
    margin-bottom: 3rem;

    @include desktop {
      font-size: 3.4375rem;
      margin-bottom: 6.25rem;      
    }
  }
}

.solutions-tiles {
  display: flex;
  flex-direction: column;

  @include desktop {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 4.375rem;
    grid-row-gap: 4.0625rem;
    margin: 0 .9375rem;
  }

  .tile {
    margin: 0 .9375rem 3rem;    

    img {
      width: 100%;
      margin-bottom: 1rem;
    }

    h3 {
      font-size: 1.1875rem;
      margin-bottom: 1rem;
    }

    p {
      font-size: .6875rem;
      line-height: 1.8;

      @include desktop {
        font-size: .8125rem;
      }
    }

    &.quote-cta {
      background: url('images/solutions/stamp-bg-mobile.png') 0 0 no-repeat;
      background-size: cover;
      padding: 4rem 1.5rem;
      max-height: 55%;

      h4 {
        font-size: 1.375rem;
        line-height: 1.3;
        margin-bottom: 1.5rem;
      }

      p {
        font-size: .8125rem;
        line-height: 1.7;
        margin-bottom: 2rem;
      }

      @include desktop {
        background: url('images/solutions/stamp-bg.png') 0 0 no-repeat;
        background-size: cover;
        padding: 5rem 2rem;

        h4 {
          font-size: 2.375rem;
          line-height: 1.3;
        }

      }      
    }
  }
}