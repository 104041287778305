.about {
  padding-top: 3.375rem;

  .hero-image {
    margin-bottom: 2.5rem;
  }

  .hero-text {
    padding: 0 .9375rem;
    margin-bottom: 2.5rem;    

    h1 {
      margin-bottom: 2.5rem;

      @include desktop {
        font-size: 3.4375rem;
        line-height: 4.25rem;
      }

    }

    p {
      font-size: .8125rem;
      line-height: 1.7;
      margin-bottom: 1.875rem;

      @include desktop {
        font-size: 1rem;
        line-height: 1.75rem;
      }

    }

  }

  .video {
    flex-direction: column-reverse;
    padding: 0 .9375rem;

    @include desktop {
      flex-direction: row-reverse;
    }

    .text-block {
      margin-bottom: 0;
    }

    h3 {
      margin: 0 0 2rem;
    }

    p {
      font-size: .8125rem;
      line-height: 1.7;
      margin-bottom: 1.875rem;

      &:last-child {
        margin-bottom: 0;
      }

      @include desktop {
        font-size: .8125rem;
        line-height: 1.8;
        margin-bottom: 2.5rem;
      }
    }
  }
}